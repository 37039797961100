<script>
import get from 'lodash-es/get'
import modal from '/~/core/mdl'
import BaseButton from '/~/components/base/button/base-button.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseMdl from '/~/components/mdl/mdl-popup.vue'

export default {
  name: 'rec-group-leave-modal',
  components: {
    BaseButton,
    BaseIcon,
    BaseMdl,
  },
  props: {
    group: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      error: null,
      processing: false,
    }
  },
  methods: {
    async onLeave() {
      const save = async (memberId) => {
        try {
          this.processing = true

          if (this.group.isMeOwner) {
            await this.group.updateMember(memberId, { permission: 1 })
          }

          await this.group.leave()
          this.$emit('success')
          this.onClose()
        } catch (error) {
          this.error = get(error, 'response.data.message', 'Network error')
        } finally {
          this.processing = false
        }
      }

      if (this.group.isMeOwner) {
        modal.show('rec-members-manager', {
          props: {
            title: 'Select new Admin',
            selectable: true,
            source: this.group,
          },
          on: {
            save: (member) => {
              save(member.id)
            },
          },
        })
      } else save()
    },
    onClose() {
      modal.hide('rec-group-leave')
    },
  },
}
</script>

<template>
  <base-mdl :layout="false" :closable="!processing" width="xse">
    <div class="relative p-5 sm:p-[30px]">
      <div class="absolute right-0 top-0 m-5 sm:m-[30px]">
        <base-button icon="plain/close" @click="onClose" />
      </div>

      <div class="mb-6 mt-[30px] text-center">
        <base-icon
          :svg="error ? 'rec/alert' : 'rec/leave'"
          :size="64"
          class="mx-auto text-fg-error"
        />
      </div>

      <template v-if="error">
        <h2 class="text-center leading-tight">Error while leaving Group</h2>
        <p class="mt-[30px]">
          {{ error }}
        </p>
        <p>Please, try again later.</p>
        <base-button class="mt-10" full-width @click="onClose">
          Close
        </base-button>
      </template>
      <template v-else>
        <h2 class="text-center leading-tight">
          Are you sure you wish to leave this Group?
        </h2>
        <base-button
          class="mt-10"
          full-width
          :loading="processing"
          @click="onLeave"
        >
          Leave
        </base-button>
      </template>
    </div>
  </base-mdl>
</template>
